<template>
  <transition name="fade">
    <div
      class="container-not"
      v-if="fadeNotificationCart"
    >
      <div class="triangle-content triangle-form" />
      <div class="content-notification">
        <div
          style="position: relative;"
          class="display-flex align-items-center"
        >
          <p class="text-title-notify mon-regular">{{ texts.notificationcart.textTitleNotification }}</p>
          <div style="position: absolute; right: 0px;">
            <v-btn
              @click="$store.commit('setFadeNotificationCart',false)"
              icon
            >
              <v-icon
                class="ml-1"
                color="#283C4D"
                size="18px"
              >
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div :class="(products.length > 2) ? 'scroller' : ''">
          <div
            v-for="(item, index) in products"
            :key="index"
            class="display-flex align-items-center mt-5"
          >
            <div class="content-image">
              <img
                :src="item.aProducts[0].sImageUrl"
                alt=""
              >
            </div>
            <div style="width: 100%;">
              <p class="product-text-title mon-medium">{{ item.aProducts[0].sProductName }}</p>
              <p class="product-text-location mon-medium">
                <v-icon
                  size="18px"
                  color="#9AA6B1"
                >
                  mdi-map-marker
                </v-icon>
                {{ item.sWarehouseAddress }}
              </p>
              <!-- <p class="product-text-reviews mon-medium">(15 {{ texts.notificationcart.textReviews }})</p> -->
              <p class="product-text-price mon-bold">${{ formatMoney(item.aProducts[0].dTotalPrice) }} USD</p>
            </div>
          </div>
        </div>
        <div>
          <v-btn
            @click="redirectShoppingCart"
            elevation="0"
            class="button-shopping-cart mon-bold"
          >
            {{ texts.notificationcart.buttonShowShoppinCart}}
            <v-icon
              color="#283C4D"
              size="16px"
              class="ml-1"
            >
              mdi-cart-outline
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "NotificationCartComponent",
  data() {
    return {
      //VARIABLES
      products: [],
    };
  },
  beforeMount() {
    this.texts = FILE.notificationcart[this.selectLanguage];
  },
  methods: {
    formatMoney(money) {
      //return number in format money
      return FORMAT_MONEY(money);
    },
    getShoppingCart: function () {
      const params = {
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`, params, config 
      )
        .then((response) => {
          this.products = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    redirectShoppingCart: function () {
      this.$router.push("/shopping-cart").catch(() => {});
      this.$store.commit("setFadeNotificationCart", false);
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    fadeNotificationCart: function () {
      return this.$store.state.fadeNotificationCart;
    },
  },
  watch: {
    fadeNotificationCart: function () {
      if (this.fadeNotificationCart) {
        this.getShoppingCart();
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.notificationcart[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.scroller {
  height: 300px;
  overflow: auto;
}

.button-shopping-cart {
  background-color: #ffffff !important;
  width: 100%;
  height: 40px !important;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
  margin-top: 25px;
}

.product-text-price {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
  text-transform: initial;
}

.product-text-reviews {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 5px;
  text-transform: initial;
}

.product-text-location {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 5px;
  text-transform: initial;
}

.product-text-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
  text-transform: initial;
}

.content-image {
  width: 90px;
  height: 90px;
  margin-right: 7px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

.text-title-notify {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-transform: initial;
  margin-bottom: 0px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.container-not {
  position: absolute;
  top: 37px;
  right: 118px;
  z-index: 2;
}

.content-notification {
  width: 450px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 13px;
  opacity: 1;
  margin-top: -7px;
  margin-right: -13px;
  padding: 10px;
}

.triangle-content {
  display: inline-block;
  margin-left: 408px;
  vertical-align: middle;
}

.triangle-form {
  width: 20px;
  height: 5px;
  border-bottom: solid 10px rgb(255, 255, 255);
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

@media (max-width: 960px) {
  .container-not {
    position: absolute;
    top: 37px;
    right: 140px;
  }
}

@media (max-width: 600px) {
  .content-notification {
    width: 100%;
    margin-right: -13px;
    margin-top: 30px;
  }
  .container-not {
    left: 12px;
    width: 95%;
  }
  .triangle-content {
    /* margin-right: 50px; */
    display: none;
  }
  .text-title-notify {
    font-size: 16px;
  }
  .product-text-price {
    font-size: 18px;
  }

  .product-text-reviews {
    font-size: 10px;
  }

  .product-text-location {
    font-size: 10px;
  }

  .product-text-title {
    font-size: 14px;
  }
}
</style>
